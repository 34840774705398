<template>
  <div>
    <div class="content-header">
      <header class="header">收银台</header>
    </div>

    <div class="logo">
      <img src="../assets/img/logo.png" />
      <p class="logo-title">中愈智谷</p>
    </div>
    <section class="layou">
      <div class="merchant">
        <span class="span-title">商家名称：</span>
        <span>{{ name }}</span>
      </div>
      <div class="merchant">
        <span class="span-title">支付金额</span>
        <span>
          <input type="text" v-model="money" placeholder="请输入支付金额" />
        </span>
      </div>
      <div class="merchant">
        <span class="span-title">手机号</span>
        <span v-show="this.make == '2'">
          {{ this.phone }}
        </span>

        <span v-show="this.make == '1'">
          <input
            type="text"
            v-model="phone"
            placeholder="请输入手机号"
          />
        </span>

      </div>
      <!-- <button id="test" @click="test(123)">立即支付</button> -->

      <div v-show="paybtn" class="btn" @click="pay">
        <button>立即支付</button>
      </div>
    </section>
  </div>
</template>

<script src="https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js"></script>
<script>
//  2存在 1 不存在

import { getUrlKey } from "@/utils/utils";
import axios from "axios";



export default {
  name: "Home",

  created() {
    var state = getUrlKey("state", window.location.href);
    this.name = state.split(",")[0];
    this.mer_id = state.split(",")[1];

    let that = this;
    ap.getAuthCode(
      {
        appId: "2021004101603973",
        scopes: ["auth_base"],
      },
      function (res) {
        console.log(res)
        if (res.authCode) {
          var url =
            "https://www.zhongyuzhigu.com/api/user/dev?auth_code=" + res.authCode;
          axios
            .get(url)
            .then((response) => {
              //     ap.alert("正确" + JSON.stringify(response.data));
              if (response.data.status == 200) {
                that.buyerid = response.data.data.buyerid;
                that.make = response.data.data.mark;

                if (that.make == "2") {
                  that.phone = response.data.data.phone;
                } else {
                  alert("你当前未绑定中愈智谷账号请输入手机号绑定!");
                }
              } else {
                ap.alert(response.data.msg);
              }
            })
            .catch((response) => {
              ap.alert(response.data.msg);
            });
        } else {
          ap.alert(res.errorMessage);
        }
      }
    );
  },
  data() {
    return {
      money: "",
      phone: "",
      buyerid: "",
      name: "",
      mer_id: "",
      make: "",
      paybtn: true,
    };
  },
  methods: {
    // test(id){
    //   alert(123)
    //   ap.getAuthCode({    
    //     appId: '9021000131692885',    
    //     scopes: ['auth_user'],  
    //   }, function(res){   
    //     ap.alert(JSON.stringify(res));  
    //   });
    // },
    pay() {
      let that = this;

      if (this.money == "") {
        alert("金额不能为空");
        return;
      }

      // make ==1 严重手机号
      if (this.make == "1") {
        if (this.phone == "") {
          alert("手机号不能为空");
          return;
        }
      }

      that.paybtn = false;

      var url = "https://www.zhongyuzhigu.com/api/user/payPoly";
   
        let shoujihao = "";
      if(this.make == "1"){
         shoujihao = this.phone;
      } 
      


      axios
        .post(url, {
          iphone: shoujihao,
          pay_money: this.money,
          uid: this.buyerid,
          mer_id: this.mer_id,
          mark: "alipay",
        })
        .then(
          (response) => {
            if (response.data.status == 200) {
              window.location.href = response.data.data;
            } else {
              alert(response.data.msg);
              that.paybtn = true;
            }
          },
          (response) => {
            alert("系统异常");
            that.paybtn = true;
          }
        );
    },
  },
};
</script>
<style lang="scss" scoped>
.content-header {
  position: relative;
  height: 276px;
  background: #f7f7f7;
  font-family: Adobe Heiti Std;
}
.header {
  height: 148px;
  font-size: 18px;
  padding-top: 4px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  text-shadow: 0px 0px 0px rgba(31, 26, 4, 0.35);
  text-align: center;
  background: #fdca00;
}
.logo {
  position: absolute;
  top: 81px;
  left: 50%;
  margin-left: -2.185rem;
  width: 4.37rem;
  height: 130px;
  img {
    width: 4.37rem;
  }
  .logo-title {
    margin-top: 7px;
    font-size: 16px;
    font-weight: normal;
    color: #666666;
    // line-height: 29px;
    text-align: center;
  }
}
.layou {
  height: calc(100vh - 276px);
  background: #f7f7f7;
  .merchant {
    height: 43px;
    line-height: 43px;
    padding-left: 0.58rem;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    color: #333333;
    background: #fff;
    .span-title {
      display: inline-block;
      width: 4.2rem;
      height: 100%;
    }
    input {
      font-size: 0.68rem;
      font-weight: normal;
      color: #cbcbcb;
    }
    input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #cbcbcb;
    }
    input:-moz-input-placeholder {
      font-size: 16px;
      font-weight: normal;
      color: #cbcbcb;
    }
    input::-moz-input-placeholder,
    input::-ms-input-placeholder {
      font-size: 0.68rem;
      font-weight: normal;
      color: red;
    }
  }
  .btn {
    position: fixed;
    bottom: 33px;
    width: 100%;
    height: 1.89rem;
    text-align: center;
    button {
      margin: 0 auto;
      width: 14.72rem;
      height: 1.89rem;
      font-size: 18px;
      text-shadow: 0px 0px 0px rgba(31, 26, 4, 0.07);
      font-weight: normal;
      font-family: Adobe Heiti Std;
      color: #ffffff;
      background: #fdca00;
      border-radius: 5px;
    }
  }
}
</style>
